import React from "react";
import { IRenderSearchResultsProps, SiteSearchResultModel } from "../Contracts/contracts";
import "../Style/SiteSearch.scss";

export const RenderSearchResults = (props: IRenderSearchResultsProps) => {
	const { results } = props;

	if (!results?.Results) return <></>;

	return (
		<div className="search-results-wrapper">
			{`Total results: ${results.Total}`}
			{results?.Results?.map((result: SiteSearchResultModel) => {
				return (
					<div className="search-results-item" key={result.Id}>
						<a href={`${result.UrlName}`}>
							<p className="search-results-item-heading">{result.PageHeading}</p>
						</a>
						<p className="search-results-item-date">
							{result.CreateDate ? `Created on: ${result.CreateDate} ` : ""}
						</p>
						<p className="search-results-item-date">
							{result.UpdateDate ? `Last updated on: ${result.UpdateDate}` : ""}
						</p>
					</div>
				);
			})}
		</div>
	);
};
